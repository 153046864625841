.page__container {
  background-color: #ffffff;
}

.article {
  /* 1920+ */
  --article-full-width: 980px;
  --article-full-horizontal-padding: 36px;
  --article-full-image-width: 66vw;
  --article-full-image-height: 20vw;
  --article-full-title-font-size: 32px;

  /* 1280 - 1920 */
  --article-desktop-width: 980px;
  --article-desktop-horizontal-padding: 36px;
  --article-desktop-image-width: 66vw;
  --article-desktop-image-height: 20vw;
  --article-desktop-title-font-size: 32px;

  /* 1024 - 1280 */
  --article-laptop-width: 752px;
  --article-laptop-horizontal-padding: 36px;
  --article-laptop-image-width: 66vw;
  --article-laptop-image-height: 20vw;
  --article-laptop-title-font-size: 28px;

  /* 768 - 1024 */
  --article-tablet-width: 696px;
  --article-tablet-horizontal-padding: 36px;
  --article-tablet-image-width: 100%;
  --article-tablet-image-height: 20vw;
  --article-tablet-title-font-size: 28px;

  /* 0 - 768 */
  --article-mobile-width: 100%;
  --article-mobile-horizontal-padding: 36px;
  --article-mobile-image-width: 100%;
  --article-mobile-image-height: 20vw;
  --article-mobile-title-font-size: 24px;

  padding-top: 30px;
  padding-bottom: 30px;

  background-color: #ffffff;

  letter-spacing: -0.05em;

  --article-download-border: solid 1px rgba(17, 17, 17, 0.1);
  --article-download-border-radius: 8px;
  --article-download-padding: 20px;
  --article-download-link-background-color: #3472bc;
  --article-download-link-color: #ffffff;
  --article-download-link-padding: 14px 28px;

  --article-next-previous-heading-font-size: 18px;
  --article-next-previous-heading-font-weight: 500;
  --article-next-previous-heading-line-height: 1.2;
}

.article__content_width {
  width: var(--article-mobile-width);
  padding-left: var(--article-mobile-horizontal-padding);
  padding-right: var(--article-mobile-horizontal-padding);

  margin: 0 auto;
}

.article__image {
  width: var(--article-mobile-image-width);
  height: var(--article-mobile-image-height);
  margin: 0 auto;
  padding-left: var(--article-mobile-horizontal-padding);
  padding-right: var(--article-mobile-horizontal-padding);
}

.article__image__image {
  background-size: cover;
  width: 100%;
  height: 100%;
}

.article__landscape_image {
  width: 100%;
  margin: 0 auto;
  padding-left: var(--article-mobile-horizontal-padding);
  padding-right: var(--article-mobile-horizontal-padding);
  position: relative;
}

/* .article__landscape_image img {
    width: 100%;
    max-width: 1264px;
  } */

.article__video {
  width: 100%;
  text-align: center;
}

.article__title {
  font-size: var(--article-mobile-title-font-size);
  font-weight: 600;
  text-align: center;
  line-height: 1.2;
  margin-bottom: 1em;
}

/* Tablet */
@media screen and (min-width: 768px) {
  .article__content_width {
    width: var(--article-tablet-width);
    padding-left: var(--article-tablet-horizontal-padding);
    padding-right: var(--article-tablet-horizontal-padding);
  }

  .article__image {
    width: var(--article-tablet-image-width);
    height: var(--article-tablet-image-height);
    padding-left: var(--article-tablet-horizontal-padding);
    padding-right: var(--article-tablet-horizontal-padding);
  }

  .article__landscape_image {
    padding-left: var(--article-tablet-horizontal-padding);
    padding-right: var(--article-tablet-horizontal-padding);
  }

  .article__title {
    font-size: var(--article-tablet-title-font-size);
  }
}

/* Laptop */
@media screen and (min-width: 1024px) {
  .article__content_width {
    width: var(--article-laptop-width);
    padding-left: var(--article-laptop-horizontal-padding);
    padding-right: var(--article-laptop-horizontal-padding);
  }

  .article__image {
    width: var(--article-laptop-image-width);
    height: var(--article-laptop-image-height);
    padding-left: var(--article-laptop-horizontal-padding);
    padding-right: var(--article-laptop-horizontal-padding);
  }

  .article__landscape_image {
    padding-left: var(--article-laptop-horizontal-padding);
    padding-right: var(--article-laptop-horizontal-padding);
  }

  .article__title {
    font-size: var(--article-laptop-title-font-size);
  }
}

/* Desktop */
@media screen and (min-width: 1280px) {
  .article__content_width {
    width: var(--article-desktop-width);
    padding-left: var(--article-desktop-horizontal-padding);
    padding-right: var(--article-desktop-horizontal-padding);
  }

  .article__image {
    width: var(--article-desktop-image-width);
    height: var(--article-desktop-image-height);
    padding-left: var(--article-desktop-horizontal-padding);
    padding-right: var(--article-desktop-horizontal-padding);
  }

  .article__landscape_image {
    width: 1264px;
    padding-left: var(--article-desktop-horizontal-padding);
    padding-right: var(--article-desktop-horizontal-padding);
  }

  .article__title {
    font-size: var(--article-desktop-title-font-size);
  }
}

/* 4K */
@media screen and (min-width: 1920px) {
  .article__content_width {
    width: var(--article-full-width);
    padding-left: var(--article-full-horizontal-padding);
    padding-right: var(--article-full-horizontal-padding);
  }

  .article__image {
    width: var(--article-full-image-width);
    height: var(--article-full-image-height);
    padding-left: var(--article-full-horizontal-padding);
    padding-right: var(--article-full-horizontal-padding);
  }

  .article__landscape_image {
    padding-left: var(--article-full-horizontal-padding);
    padding-right: var(--article-full-horizontal-padding);
  }

  .article__title {
    font-size: var(--article-full-title-font-size);
  }
}

.article__header {
  padding-top: 32px;
  margin-bottom: 40px;
  font-size: 13px;
  font-weight: 400;
  position: relative;
}

.article__header__content {
  display: grid;
  grid-template-columns: 1fr max-content 1fr;
  grid-template-areas: 'category author date';
  align-items: center;
  padding-bottom: 16px;
  border-bottom: solid 1px #5b5b5b;
}

.article__header__category {
  grid-area: category;
}

.article__header__author {
  justify-self: center;
  position: relative;
  grid-area: author;
}

.article__header__author_image {
  position: absolute;
  top: 0;
  left: 50%;
  transform: translate(-50%, -115%);
  width: 50px;
  height: 50px;
}

.article__header__author_image img {
  border: solid 3px #ffffff;
  border-radius: 50%;
}

.article__header__date {
  justify-self: end;
  grid-area: date;
}

.article__body figure {
  margin-inline-start: 0;
  margin-inline-end: 0;
}

.article__body figcaption {
  margin-top: 0.5rem;
  margin-bottom: 1.3rem;
  font-weight: 500;
}

.article__body.article__body__gated_content {
  height: 0;
  overflow: hidden;
}

/* .article__content {
  
  } */

.article__content ul {
  margin: 0 0 0 30px;
  list-style-type: disc;
}

.article__content li {
  margin-bottom: 1rem;
}

.article__content h2 {
  font-size: 24px;
  font-weight: 600;
  line-height: 1.2;
}

.article__content img {
  max-width: 100%;
  display: block;
  height: auto;
}

.article__content iframe {
  width: 100%;
}

.article__not_found {
  font-size: 1.2rem;
  margin-bottom: 20px;
}

.article__not_found + a {
  text-decoration: none;
}

.article__download_link {
  border: var(--article-download-border);
  border-radius: var(--article-download-border-radius);
  padding: var(--article-download-padding);

  display: grid;
  grid-template-columns: 1fr max-content;
  column-gap: 20px;
  align-items: center;
}

.article__download_link__button a {
  background-color: var(--article-download-link-background-color);
  color: var(--article-download-link-color);
  padding: var(--article-download-link-padding);
  text-decoration: none;
}

.article__download_link__title {
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 0.5rem;
}

.article__download_link__name {
  font-size: 14px;
  font-weight: 500;
}

.article__download_link__name a {
  text-decoration: none;
  color: #111111;
}

.article__download_link__icon {
  display: inline-block;
}

.article__download_link__icon svg {
  width: 24px;
  height: 24px;
  vertical-align: middle;
  margin-right: 5px;
  color: red;
}

.article__next_previous,
.article__social_media {
  margin-top: 40px;
  margin-bottom: 40px;
  padding-top: 20px;
  border-top: solid 1px #5b5b5b26;
}

.article__next_previous__heading,
.article__social_media__heading {
  font-size: var(--article-next-previous-heading-font-size);
  font-weight: var(--article-next-previous-heading-font-weight);
  line-height: var(--article-next-previous-heading-line-height);
  margin-bottom: 10px;
}

.article__next_previous__content > *:not(:last-child) {
  margin-bottom: 10px;
  padding-bottom: 10px;
  border-bottom: solid 1px #5b5b5b26;
}

.article__next_previous__content .article__next_previous a {
  text-decoration: none;
}

.article__pardot_form {
  border: solid 1px #5b5b5b26;
  padding: 20px;
  border-radius: 6px;
  margin-top: 20px;
  background-color: #f5f5f5;
}

.article__pardot_form__heading {
  margin-bottom: 10px;
  font-size: 15px;
  font-weight: 600;
}

.banner {
  display: grid;
  grid-template-columns: max-content 1fr max-content;
  column-gap: 10px;
  grid-template-areas:
    'banner-icon banner-title banner-cta'
    'banner-icon banner-body banner-cta';
  width: 100%;
  border-radius: 8px;
  border: 1px solid rgba(17, 17, 17, 0.1);
  padding: 24px;

  background-color: #e0e0e0;
}

.banner .banner__icon {
  grid-area: banner-icon;
  align-self: center;
}

.banner .banner__title {
  grid-area: banner-title;
  font-size: 16px;
  font-weight: 500;
}

.banner .banner__body {
  grid-area: banner-body;
  font-size: 10px;
  font-weight: 400;
}

.banner .banner__body p {
  margin: 0;
}

.banner .banner__cta {
  grid-area: banner-cta;
  align-self: center;
}

.banner .banner__cta a {
  background-color: #3472bc;
  border-radius: 4px;
  padding: 15px 24px;
  color: #ffffff;
  text-decoration: none;
}
