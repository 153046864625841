.social_media__container {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  justify-items: center;
  margin-top: 20px;
}

.social_media__link {
  border: solid 1px #5b5b5b26;
  width: 100%;
  text-align: center;
  padding: 10px 0 4px 0;
}

.social_media__link:not(:last-of-type) {
  border-right: none;
}

.twitter_icon {
  width: 32px;
  height: 32px;
  border-radius: 50%;
  background-color: #000000;
  color: #ffffff;
  display: inline-flex;
}

.twitter_icon svg {
  fill: white;
  height: 14px;
  align-self: center;
  justify-self: center;
}

.twitter_icon svg path {
  stroke-width: 32px;
  stroke: #ffffff;
}
