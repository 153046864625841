.pulse {
  /* 1920+ */
  --grid-full-column-width: 380px;
  --grid-full-row-height: 210px;
  --grid-full-num-columns: 4;

  /* 1280 - 1920 */
  --grid-desktop-column-width: 300px;
  --grid-desktop-row-height: 210px;
  --grid-desktop-num-columns: 4;

  /* 1024 - 1280 */
  --grid-laptop-column-width: 300px;
  --grid-laptop-row-height: 190px;
  --grid-laptop-num-columns: 2;

  /* 768 - 1024 */
  --grid-tablet-column-width: 348px;
  --grid-tablet-row-height: 190px;
  --grid-tablet-num-columns: 2;

  /* 0 - 768 */
  --grid-mobile-column-width: 375px;
  --grid-mobile-row-height: 140px; /* used at panel level */
  --grid-mobile-num-columns: 1;

  --panel__full__padding: 50px 50px 30px 50px;
  --panel__desktop__padding: 40px 40px 20px 40px; /* 60px 60px 30px 60px; */
  --panel__laptop__padding: 40px 30px 20px 30px;
  --panel__tablet__padding: 40px 40px 20px 40px;
  --panel__mobile__padding: 20px 20px 20px 20px;

  /* Padding for when the content is in the bottom half of a panel */
  --panel__full__bottom_content__padding: 40px 50px 30px 50px;
  --panel__desktop__bottom_content__padding: 30px 40px 20px 40px; /* 60px 60px 30px 60px; */
  --panel__laptop__bottom_content__padding: 20px 30px 20px 30px;
  --panel__tablet__bottom_content__padding: 40px 40px 20px 40px;
  --panel__mobile__bottom_content__padding: 20px 20px 20px 20px;

  --pulse-full-content-width: calc(
    var(--grid-full-column-width) * var(--grid-full-num-columns)
  );
  --pulse-desktop-content-width: calc(
    var(--grid-desktop-column-width) * var(--grid-desktop-num-columns)
  );
  --pulse-laptop-content-width: calc(
    var(--grid-laptop-column-width) * var(--grid-laptop-num-columns)
  );
  --pulse-tablet-content-width: calc(
    var(--grid-tablet-column-width) * var(--grid-tablet-num-columns)
  );
  --pulse-mobile-content-width: calc(
    var(--grid-mobile-column-width) * var(--grid-mobile-num-columns)
  );

  --pulse-full-margin-top: 60px;
  --pulse-desktop-margin-top: 40px;
  --pulse-laptop-margin-top: 40px;
  --pulse-tablet-margin-top: 36px;
  --pulse-mobile-margin-top: 24px;

  --pulse-section-full-margin-bottom: 60px;
  --pulse-section-desktop-margin-bottom: 40px;
  --pulse-section-laptop-margin-bottom: 40px;
  --pulse-section-tablet-margin-bottom: 30px;
  --pulse-section-mobile-margin-bottom: 20px;

  --pulse-button-border: solid #4b91e480 1px;
  --pulse-button-border-radius: 4px;
  --pulse-button-color: #3472bc;
  --pulse-button-font-size: 16px;
  --pulse-button-line-height: 1.2;
  --pulse-button-letter-spacing: -0.05em;
  --pulse-button-width: 220px;
  --pulse-button-height: 56px;

  margin-bottom: 20px;
}

/* The different page sections, initially only a single column */
.pulse__sections {
  display: grid;

  margin-top: var(--pulse-mobile-margin-top);
}

/* Variable Google banners will appear in the markup but only one will display content, so target only non-empty containers for bottom margin */
.pulse__sections_separate > div:not(:empty) {
  margin-bottom: var(--pulse-section-mobile-margin-bottom);
}

.pulse__sections_continuous .pulse__banner:not(:empty) {
  margin-top: 10px;
  margin-bottom: 10px;
}

/* Tablet */
@media screen and (min-width: 768px) {
  .pulse__sections {
    margin-top: var(--pulse-tablet-margin-top);
  }

  .pulse__sections_separate > div:not(:empty) {
    margin-bottom: var(--pulse-section-tablet-margin-bottom);
  }
}

/* Laptop */
@media screen and (min-width: 1024px) {
  .pulse__sections {
    margin-top: var(--pulse-laptop-margin-top);
  }

  .pulse__sections_separate > div:not(:empty) {
    margin-bottom: var(--pulse-section-laptop-margin-bottom);
  }
}

/* Desktop */
@media screen and (min-width: 1280px) {
  .pulse__sections {
    margin-top: var(--pulse-desktop-margin-top);
  }

  .pulse__sections_separate > div:not(:empty) {
    margin-bottom: var(--pulse-section-desktop-margin-bottom);
  }
}

/* 4K */
@media screen and (min-width: 1920px) {
  .pulse__sections {
    margin-top: var(--pulse-full-margin-top);
  }

  .pulse__sections_separate > div:not(:empty) {
    margin-bottom: var(--pulse-section-full-margin-bottom);
  }
}

.pulse__content,
.pulse__content_bg {
  width: var(--pulse-mobile-content-width);
  margin: 0 auto; /* centered */
  padding: 1rem;
  letter-spacing: -0.05em;
}

.pulse__content {
  background-color: #ffffff;
}

/* Tablet */
@media screen and (min-width: 768px) {
  .pulse__content,
  .pulse__content_bg {
    width: var(--pulse-tablet-content-width);
  }
}

/* Laptop */
@media screen and (min-width: 1024px) {
  .pulse__content,
  .pulse__content_bg {
    width: var(--pulse-laptop-content-width);
  }
}

/* Desktop */
@media screen and (min-width: 1280px) {
  .pulse__content,
  .pulse__content_bg {
    width: var(--pulse-desktop-content-width);
  }
}

/* 4K */
@media screen and (min-width: 1920px) {
  .pulse__content,
  .pulse__content_bg {
    width: var(--pulse-full-content-width);
  }
}

.pulse__content__not_found {
  font-size: 1.2rem;
  margin-bottom: 20px;
}

.pulse__content__not_found + a {
  text-decoration: none;
}

.pulse .pulse__show_more button {
  width: max-content;
  border: var(--pulse-button-border);
  border-radius: var(--pulse-button-border-radius);

  color: var(--pulse-button-color);
  font-size: var(--pulse-button-font-size);
  line-height: var(--pulse-button-line-height);
  letter-spacing: var(--pulse-button-letter-spacing);

  width: var(--pulse-button-width);
  height: var(--pulse-button-height);

  cursor: pointer;
}

.pulse .pulse__show_more button:disabled {
  cursor: auto;
}

.pulse__show_more {
  margin: 60px auto;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}

.pulse__spinner {
  display: block;
  width: 30px;
  height: 30px;
  animation: spin 2000ms linear infinite;
  margin: 0 auto;
}
