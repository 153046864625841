/* Matches the content width */
.pulse__heading {
  --pulse__title__font-weight: 600;
  --pulse__title__color: inherit;

  --pulse__full__title__font-size: 48px;
  --pulse__desktop__title__font-size: 48px;
  --pulse__laptop__title__font-size: 36px;
  --pulse__tablet__title__font-size: 36px;
  --pulse__mobile__title__font-size: 32px;

  --pulse__title__padding: 0.75em 0 0.5em 0;

  --pulse__full__subtitle__font-size: 18px;
  --pulse__desktop__subtitle__font-size: 16px;
  --pulse__laptop__subtitle__font-size: 14px;
  --pulse__tablet__subtitle__font-size: 14px;
  --pulse__mobile__subtitle__font-size: 11px;

  --pulse__subtitle__padding: 0 0 1em 0;

  width: 100%;
  background-color: #ffffff;
  margin-bottom: 20px;
}

.pulse__heading__content {
  margin: 0 auto;
  /* centered */
  padding-left: 0.5em;
  padding-right: 0.5em;
}

.pulse__heading__content__title {
  font-weight: var(--pulse__title__font-weight);
  font-size: var(--pulse__mobile__title__font-size);
  color: var(--pulse__title__color);
  line-height: 1em;
  padding: var(--pulse__title__padding);
}

.pulse__heading__content__subtitle {
  font-size: var(--pulse__mobile__subtitle__font-size);
  color: var(--pulse__title__color);
  padding: var(--pulse__subtitle__padding);
  line-height: 1.2;
}

/* Tablet */
@media screen and (min-width: 768px) {
  .pulse__heading {
    margin-bottom: 40px;
  }

  .pulse__heading__content {
    width: var(--pulse-tablet-content-width);
    padding: initial;
  }

  .pulse__heading__content__title {
    font-size: var(--pulse__tablet__title__font-size);
  }

  .pulse__heading__content__subtitle {
    font-size: var(--pulse__tablet__subtitle__font-size);
  }
}

/* Laptop */
@media screen and (min-width: 1024px) {
  .pulse__heading {
    margin-bottom: 40px;
  }

  .pulse__heading__content {
    width: var(--pulse-laptop-content-width);
  }

  .pulse__heading__content__title {
    font-size: var(--pulse__laptop__title__font-size);
  }

  .pulse__heading__content__subtitle {
    font-size: var(--pulse__laptop__subtitle__font-size);
  }
}

/* Desktop */
@media screen and (min-width: 1280px) {
  .pulse__heading {
    margin-bottom: 40px;
  }

  .pulse__heading__content {
    width: var(--pulse-desktop-content-width);
  }

  .pulse__heading__content__title {
    font-size: var(--pulse__desktop__title__font-size);
  }

  .pulse__heading__content__subtitle {
    font-size: var(--pulse__desktop__subtitle__font-size);
  }
}

/* 4K */
@media screen and (min-width: 1920px) {
  .pulse__heading {
    margin-bottom: 60px;
  }

  .pulse__heading__content {
    width: var(--pulse-full-content-width);
  }

  .pulse__heading__content__title {
    font-size: var(--pulse__full__title__font-size);
  }

  .pulse__heading__content__subtitle {
    font-size: var(--pulse__full__subtitle__font-size);
  }
}
