.feature_panel {
  --feature_panel__letter-spacing: -0.05em;
  --feature_panel__line-height: 1.2;

  /* The text above the main title, consistent on all panel types */
  --feature_panel__heading__font-size: 11px;
  --feature_panel__heading__font-weight: 400;
  --feature_panel__heading__color: #3472bc;

  --feature_panel__title__color: #111111;
  --feature_panel__title__color--hover: #3472bc;
  --feature_panel__title__decoration: none;

  --feature_panel__background-color: inherit;
  --feature_panel__border: solid 1px rgba(17, 17, 17, 0.1);

  /* The text above the main title, consistent on all panel types */
  --feature_panel__subtitle__font-size: 11px;
  --feature_panel__subtitle__font-weight: 400;
  --feature_panel__subtitle__color: #111111;
}

.feature_panel {
  --feature_panel__title__font-weight: 500;

  --feature_panel__full__title__font-size: 16px;
  --feature_panel__desktop__title__font-size: 16px;
  --feature_panel__laptop__title__font-size: 16px;
  --feature_panel__tablet__title__font-size: 16px;
  --feature_panel__mobile__title__font-size: 14px;
}

.feature_panel:hover .feature_panel__title {
  color: var(--feature_panel__title__color--hover);
}

.feature_panel a {
  text-decoration: none;
}

.feature_panel__content {
  display: grid;
  grid-template-columns: 1fr;
  column-gap: 20px;
  align-items: center;
}

.feature_panel__content__details {
}

.feature_panel__content__image {
  display: none;
  width: 212px;
  height: 120px;
  background-size: cover;
  background-position: center center;
  border-radius: 4px;
  position: relative;
}

.feature_panel__content__image img {
  height: inherit;
}

.feature_panel__heading {
  font-weight: var(--feature_panel__heading__font-weight);
  font-size: var(--feature_panel__heading__font-size);
  color: var(--feature_panel__heading__color);
  text-transform: uppercase;
}

.feature_panel__title {
  font-weight: var(--feature_panel__title__font-weight);
  font-size: var(--feature_panel__mobile__title__font-size);
  color: var(--feature_panel__title__color);
  text-decoration: var(--feature_panel__title__decoration);
}

.feature_panel__subtitle {
  font-weight: var(--feature_panel__subtitle__font-weight);
  font-size: var(--feature_panel__subtitle__font-size);
  color: var(--feature_panel__subtitle__color);
}

/* Tablet */
@media screen and (min-width: 768px) {
  .feature_panel__content {
    grid-template-columns: 1fr auto;
  }

  .feature_panel__content__image {
    display: block;
  }

  .feature_panel__title {
    font-size: var(--feature_panel__tablet__title__font-size);
  }
}

/* Laptop */
@media screen and (min-width: 1024px) {
  .feature_panel__title {
    font-size: var(--feature_panel__laptop__title__font-size);
  }
}

/* Desktop */
@media screen and (min-width: 1280px) {
  .feature_panel__title {
    font-size: var(--feature_panel__desktop__title__font-size);
  }
}

/* 4K */
@media screen and (min-width: 1920px) {
  .feature_panel__title {
    font-size: var(--feature_panel__full__title__font-size);
  }
}
