.form__message {
  margin-top: 15px;
  margin-bottom: 15px;
}

.form__submit_button {
  margin-top: 30px;
}

.form__field__short {
  width: 100%;
}

@media screen and (min-width: 768px) {
  .form__field__short {
    width: 60%;
  }
}
